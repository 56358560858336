import axios from "@/utils/axios";

const API_BASE: string = process.env.GATSBY_API_BASE?.trimEnd();

export const getNotifications = async (
  page?: number,
  pageSize?: number,
  isRead?: boolean,
  ) => {
  return axios.GET(API_BASE, "/api/messages/v1/notifications", {
    isRead,
    page,
    pageSize,
  });
};

export const updateNotification = async (notificationId: number) => {
  return axios.POST(API_BASE, `/api/messages/v1/notifications/${notificationId}/read`);
};

import React, { FC, useState, Dispatch, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { approveOrderStatusInfo, updateOrder } from "@/actions/orders.actions";
import { roleResolver } from "@/utils/roleResolver";
import { orderStatusResolver } from "@/utils/orderStatusResolver";

import { Modal } from "antd";
import Button, { ButtonThemeType } from "@/components/ui-kit/button";
import ModalToRevision from "@/components/pages/platform/pages/CardOrder/CardOrderControls/ModalToRevision";

import { AppStateType } from "@/reducers";
import { OrderItemsType, OrderType } from "app/types";
import {
  AdditionalProductType,
  ApprovalFactsResults,
  ApprovalFactType,
} from "@/components/pages/platform/pages/CardOrder";
import { StatusNameOrderType } from "@/utils/statusNames";

enum ApprovalRole { CONCORDANT = 1, AUDITOR = 2 };

type ControlType = {
  isShow: boolean;
  title: string;
  action: () => void;
  isDisabled?: boolean;
  theme?: ButtonThemeType;
};

interface ICardOrderControlsProps {
  order: OrderType;
  orderId: number;
  isEditMode: boolean;
  setIsEditMode: Dispatch<React.SetStateAction<boolean>>;
  currentOrder: OrderType;
  setCurrentOrder: Dispatch<React.SetStateAction<OrderType>>;
  additionalProducts: AdditionalProductType[];
  setAdditionalProducts: Dispatch<React.SetStateAction<AdditionalProductType[]>>;
  updateStatus: () => void;
  creatorsName: string;
  latestApprovalFacts: ApprovalFactType[];
  isDisabledUploadCommercialOfferBtn: boolean;
};

const CardOrderControls: FC<ICardOrderControlsProps> = ({
  order,
  orderId,
  isEditMode,
  setIsEditMode,
  currentOrder,
  setCurrentOrder,
  additionalProducts,
  setAdditionalProducts,
  updateStatus,
  creatorsName,
  latestApprovalFacts,
  isDisabledUploadCommercialOfferBtn,
}): JSX.Element => {
  const dispatch = useDispatch();

  const { userInfo } = useSelector((state: AppStateType) => state.account);

  const [isShowToRevisionModal, setIsShowToRevisionModal] = useState<boolean>(false);

  useEffect(() => {
    closeToRevisionModal();
  }, [order?.status]);

  const {
    isSupplier,
    isPurchaser,
    isAuditor,
    isConcordant,
    isController,
  } = roleResolver(userInfo?.roles);

  const {
    isApproval: isApprovalOrderStatus,
    isClosed: isClosedOrderStatus,
    isCancelled: isCancelledOrderStatus,
    isProcessing: isProcessingOrderStatus,
    isUpdated: isUpdatedOrderStatus
  } = orderStatusResolver(order?.status as StatusNameOrderType);

  const {
    isApproval: isApprovalCurrentOrderStatus,
    isNew: isNewCurrentOrderStatus,
    isPreparation: isPreparationCurrentOrderStatus,
    isUpdated: isUpdatedCurrentOrderStatus
  } = orderStatusResolver(currentOrder?.status as StatusNameOrderType);

  const isDisabledSubmitBtn: boolean = currentOrder?.items?.length === 0
    || additionalProducts?.some(({ productId }) => !productId);

  const isShowEditOrderBtn: boolean = isProcessingOrderStatus;

  const cannotApproveOrder = (approvalRole: ApprovalRole.CONCORDANT | ApprovalRole.AUDITOR): boolean => latestApprovalFacts
    ?.find(({ role }) => role === approvalRole)
    ?.result !== ApprovalFactsResults.REQUIRED;

  const isDisabledApproveBtn: boolean = (isAuditor && cannotApproveOrder(ApprovalRole.AUDITOR))
    || (isConcordant && cannotApproveOrder(ApprovalRole.CONCORDANT))
    || isEditMode;

  const isShowCancelBtn: boolean = !isCancelledOrderStatus && !isClosedOrderStatus && !isController;

  const isShowToRevisionBtn: boolean = (isPurchaser || isAuditor || isConcordant)
    && (isUpdatedOrderStatus || isApprovalOrderStatus)
    && !isDisabledApproveBtn;

  // согласовать может поставщик в статусе "В обработке";
  // заказчик в статусе "Согласование изменений";
  // аудитор или согласовант в статусе "На согласовании КП"
  const isShowApproveBtn: boolean = (isSupplier && isShowEditOrderBtn)
    || isPurchaser && isUpdatedCurrentOrderStatus
    || ((isAuditor || isConcordant) && isApprovalCurrentOrderStatus);

  const editOrder = async (): Promise<void> => {
    const productItems: AdditionalProductType[] = currentOrder?.items?.map((item: OrderItemsType) => {
      return ({ productId: item.product.id, count: item.quantity, itemPrice: item.product.price })
    });

    const additionalItems: AdditionalProductType[] = additionalProducts
      ?.filter((p: AdditionalProductType) => !!p.productId && !!p.count && !!p.itemPrice)
      ?.map(({ productId, count, itemPrice }) => {
        return ({ productId, count, itemPrice })
      });

    await dispatch(updateOrder({
      id: orderId,
      deliveryDate: currentOrder.deliveryDate,
      items: [...productItems, ...additionalItems]
    }));

    setAdditionalProducts([]);
    setIsEditMode(false);
  };

  const openCancelModal = (): void => {
    Modal.confirm({
      cancelText: "Вернуться назад",
      okText: "Отменить заказ",
      title: "Вы уверены, что хотите отменить заказ?",
      onOk() {
        dispatch(approveOrderStatusInfo(orderId, false));
      },
    });
  };

  const cancelChanges = (): void => {
    setCurrentOrder(order);
    setAdditionalProducts([]);
    setIsEditMode(false);
  };

  const openToRevisionModal = (): void => setIsShowToRevisionModal(true);

  const closeToRevisionModal = (): void => setIsShowToRevisionModal(false);

  const renderButton = (
    title: string,
    action: () => void,
    isDisabled: boolean = false,
    theme: ButtonThemeType = "filled",
  ): JSX.Element => {
    return (
      <Button
        theme={theme}
        className="mr-2"
        onClick={action}
        disabled={isDisabled}
      >
        {title}
      </Button>
    );
  };

  const editControls: JSX.Element = isSupplier && isShowEditOrderBtn && (
    isEditMode ? (
      <div className="flex">
        {renderButton("Отмена", cancelChanges)}
        {renderButton("Подтвердить", editOrder, isDisabledSubmitBtn)}
      </div>
    ) : (
      renderButton("Редактировать заказ", () => setIsEditMode(true))
    ));

  const controls: ControlType[] = [
    {
      isShow: isShowApproveBtn,
      title: "Согласовать",
      action: () => dispatch(approveOrderStatusInfo(orderId, true)),
      isDisabled: isDisabledApproveBtn,
    },
    {
      isShow: isSupplier && isPreparationCurrentOrderStatus,
      title: "Загрузить КП",
      action: updateStatus,
      isDisabled: isDisabledUploadCommercialOfferBtn,
    },
    {
      isShow: isSupplier && isNewCurrentOrderStatus,
      title: "Взять в обработку",
      action: updateStatus,
    },
    {
      isShow: isShowToRevisionBtn,
      title: "Отправить на доработку",
      action: openToRevisionModal,
      theme: "greyOutline",
    },
    {
      isShow: isShowCancelBtn,
      title: "Отменить заказ",
      action: openCancelModal,
      isDisabled: isEditMode,
      theme: "greyOutline",
    },
  ];

  const renderControls = (controls: ControlType[]): JSX.Element[] => {
    return controls
      .filter(({ isShow }) => isShow)
      .map(({ title, action, isDisabled, theme }) =>
        renderButton(title, action, isDisabled, theme)
      );
  };

  return (
    <div className={`flex ${isSupplier && isShowEditOrderBtn ? "justify-between" : "justify-end"} mt-2`}>
      {editControls}
      <div className="flex">
        {renderControls(controls)}
      </div>
      {isShowToRevisionModal && (
        <ModalToRevision
          orderId={orderId}
          isOpen={isShowToRevisionModal}
          onClose={closeToRevisionModal}
          creatorsName={creatorsName}
        />
      )}
    </div>
  );
};

export default CardOrderControls;

import React, {
  FC,
  useState,
  useEffect,
} from "react";
import dayjs from "dayjs";
import { AppStateType } from "@/reducers";
import { useDispatch, useSelector } from "react-redux";
import { getSuppliersList } from "@/actions/suppliers.actions";
import {
  Form,
  theme,
  Input,
  Select,
  Button, DatePicker,
} from "antd";
import { getOrdersList } from "@/actions/orders.actions";
import { roleResolver } from "@/utils/roleResolver";

const { Option } = Select;

interface ICatalogFiltersProps {
  searchValue?: string;
  setSearchValue?: React.Dispatch<React.SetStateAction<string>>;
}

export const OrdersFilters:FC<ICatalogFiltersProps> = ({searchValue, setSearchValue}) => {
  const [suppliersSearchValue, setSuppliersSearchValue] = useState<string>('');
  const [createDateFrom, setCreateDateFrom] = useState<string>('');
  const [createDateTo, setCreateDateTo] = useState<string>('');
  const [deliveryDateFrom, setDeliveryDateFrom] = useState<string>('');
  const [deliveryDateTo, setDeliveryDateTo] = useState<string>('');
  const [shipmentDateFrom, setShipmentDateFrom] = useState<string>('');
  const [shipmentDateTo, setShipmentDateTo] = useState<string>('');

  const [form] = Form.useForm();

  const { token } = theme.useToken();

  const dispatch = useDispatch();

  const { suppliers } = useSelector((state: AppStateType) => state.suppliers);
  const { userInfo } = useSelector((state: AppStateType) => state.account);

  const { isSupplier, isDemo, isPurchaser, isAuditor } = roleResolver(userInfo?.roles);

  const canGetSuppliers: boolean = isPurchaser || isAuditor;

  useEffect(() => {
    if (canGetSuppliers) {
      dispatch(getSuppliersList({ page: 1, pageSize: 25 }));
    }
  }, []);

  useEffect(() => {
    if (suppliersSearchValue?.trim() && canGetSuppliers) {
      dispatch(getSuppliersList({ page: 1, pageSize: 25, name: suppliersSearchValue }));
    }
  }, [suppliersSearchValue]);

  const applyFilters = () => {
    const orderNumber = form.getFieldValue('orderNumber');
    const supplierId = form.getFieldValue('supplierId');
    const orderStatus = form.getFieldValue('orderStatus');

    dispatch(getOrdersList({
      page: 1,
      pageSize: 25,
      statuses: orderStatus || null,
      createdFrom: createDateFrom || null,
      createdTo: createDateTo || null,
      deliveryDateFrom: deliveryDateFrom || null,
      deliveryDateTo: deliveryDateTo || null,
      shipmentDateFrom: shipmentDateFrom || null,
      shipmentDateTo: shipmentDateTo || null,
      id: orderNumber || null,
      supplierId: supplierId || null,
      search: searchValue || null,
  }))
  };

  const resetFields = () => {
    setCreateDateTo(null);
    setCreateDateFrom(null);
    setDeliveryDateFrom(null);
    setDeliveryDateTo(null);
    setShipmentDateTo(null);
    setShipmentDateFrom(null);
    setSearchValue(null);

    form.resetFields();

    dispatch(getOrdersList({ page: 1, pageSize: 25 }));
  }

  const suppliersSearch = (value: string) => {
    setSuppliersSearchValue(value);
  };

  const formStyle = {
    maxWidth: 'none',
    background: "#fff",
    borderRadius: token.borderRadiusLG,
    padding: 24,
    marginBottom: 30,
    display: "flex",
    flexDirection: "column" as "column",
    justifyContent: "bottom"
  };

  const applyCreateDateFromToFilter = (date: string[]): void => {
    setCreateDateTo(date[1]);
    setCreateDateFrom(date[0]);
  };

  const applyDeliveryDateFromToFilter = (date: string[]): void => {
    setDeliveryDateTo(date[1]);
    setDeliveryDateFrom(date[0]);
  };

  const applyShipmentDateFromToFilter = (date: string[]): void => {
    setShipmentDateTo(date[1]);
    setShipmentDateFrom(date[0]);
  };

const disabledFutureDates = (current): boolean => current && current > dayjs().endOf("day");

  return (
    <div className="relative z-10">
      <Form
        form={form}
        name="advanced_search"
        style={formStyle}
        layout="vertical"
        onFinish={applyFilters}
      >
        <div className="flex flex-wrap">
          <Form.Item
            style={{ width: '31%' }}
            className="mr-5 self-end"
            name="orderNumber"
            label="Номер заказа"
          >
            <Input
              min={0}
              type="number"
              allowClear
              placeholder="Номер заказа"
            />
          </Form.Item>
          {!isSupplier && !isDemo && (
            <Form.Item
              style={{width: "31%"}}
              className="mr-5 self-end"
              name="supplierId"
              label="Поставщик"
            >
              <Select
                value={suppliersSearchValue}
                onSearch={suppliersSearch}
                allowClear
                showSearch
                filterOption={false}
                notFoundContent={null}
                autoClearSearchValue={false}
                defaultActiveFirstOption={false}
                placeholder="Поставщик"
              >
                {suppliers?.map(({ id, name }) => {
                  return <Option key={id} value={id}>{name}</Option>;
                })}
              </Select>
            </Form.Item>
          )}
          <Form.Item
            style={{ width: '31%' }}
            className="mr-5 self-end"
            name="createDateFromTo"
            label="Дата создания от/до"
          >
            <DatePicker.RangePicker
              style={{width: "100%"}}
              allowClear
              placeholder={["Дата создания от", "Дата создания до"]}
              format="YYYY-MM-DD"
              disabledDate={disabledFutureDates}
              onChange={(date, dateString) => applyCreateDateFromToFilter(dateString)}
            />
          </Form.Item>
          <Form.Item
            style={{ width: '31%' }}
            className="mr-5 self-end"
            name="deliveryDateFromTo"
            label="Дата доставки от/до"
          >
            <DatePicker.RangePicker
              style={{width: "100%"}}
              allowClear
              placeholder={["Дата доставки от", "Дата доставки до"]}
              format="YYYY-MM-DD"
              onChange={(date, dateString) => applyDeliveryDateFromToFilter(dateString)}
            />
          </Form.Item>
          <Form.Item
            style={{ width: '31%' }}
            className="mr-5 self-end"
            name="shipmentDateFromTo"
            label="Дата отгрузки от/до"
          >
            <DatePicker.RangePicker
              style={{width: "100%"}}
              allowClear
              placeholder={["Дата отгрузки от", "Дата отгрузки до"]}
              format="YYYY-MM-DD"
              onChange={(date, dateString) => applyShipmentDateFromToFilter(dateString)}
            />
          </Form.Item>
        </div>
        <div className="flex justify-end">
          <Button
            className="mr-2"
            type="primary"
            htmlType="submit"
          >
            Применить
          </Button>
          <Button
            className="mr-5"
            onClick={resetFields}
          >
            Очистить
          </Button>
        </div>
      </Form>
    </div>
  );
};

import React, { FC, useState } from "react";
import ReactSelect, { components } from "react-select";

import { SelectDataType } from "app/types";
import { theme } from "@/../tailwind.config";
import useMobile from "@/hooks/useMobile";
import Tooltip from "@/components/ui-kit/tooltip";

import TickDown from "@/assets/icons/tick-down.inline.svg";
import CloseIcon from "@/assets/icons/close-middle.inline.svg";

import css from "./select.modules.scss";

const { ValueContainer, Placeholder, DropdownIndicator } = components;

const CustomValueContainer: FC<any> = ({ children, ...props }) => {
  return (
    <ValueContainer {...props}>
      <Placeholder {...props} isFocused={props.isFocused}>
        {props.selectProps.placeholder}
      </Placeholder>
      {React.Children.map(children, (child) =>
        child && child.key !== "placeholder" ? child : null
      )}
    </ValueContainer>
  );
};

const CustomDropdownIndicator: FC<any> = ({ children, ...props }) => {
  return (
    <DropdownIndicator {...props}>
      <TickDown className={css.icon} />
    </DropdownIndicator>
  );
};

type CustomSelectPropsType = {
  className?: string;
  placeholder: string;
  onChange?: (value: { value: any; label: any }) => void;
  options?: SelectDataType[];
  value?: {
    value: string;
    label: string;
  };
  showTooltip?: boolean;
  tooltipText?: string;
  selectTheme?: "white" | "grey";
  onSearch?: (value: string) => void;
  isError?: boolean;
  isSearchable?: boolean;
  defaultMenuIsOpen?: boolean;
  resetSetValue?: (entity: string) => void;
  name?: string;
  isClose?: boolean;
  isMobileMySelect?: boolean;
  noOptionsValue?: string;
  isLoading?: boolean;
  filterOption?: () => boolean;
};

const Select: FC<CustomSelectPropsType> = ({
  className = "",
  name = "",
  placeholder,
  onChange,
  options = [],
  showTooltip,
  tooltipText,
  selectTheme = "white",
  value,
  isError = false,
  isSearchable = true,
  defaultMenuIsOpen = false,
  resetSetValue = () => {},
  onSearch = () => {},
  isClose = true,
  isMobileMySelect = true,
  noOptionsValue = "Ничего не найдено",
  isLoading = false,
  filterOption,
}) => {
  const [isHasValue, setIsHasValue] = useState<boolean>(false);

  let isFocused = false;
  const { isMobile } = useMobile();

  return (
    <div
      className={`w-full relative flex justify-center items-center ${className}`}
    >
      <ReactSelect
        filterOption={filterOption}
        isLoading={isLoading}
        loadingMessage={() => "Загрузка..."}
        isSearchable={isSearchable}
        className={`w-full border-none text-t1 ${css.container} `}
        options={options}
        value={value}
        onInputChange={(value) => onSearch(value)}
        components={{
          ValueContainer: CustomValueContainer,
          DropdownIndicator: CustomDropdownIndicator,
        }}
        placeholder={
          <div className={`flex items-center select-none`}>
            {placeholder}
            {showTooltip && (
              <Tooltip tooltipText={tooltipText} className={`ml-1`} />
            )}
          </div>
        }
        onChange={onChange}
        styles={{
          control: (styles, state) => {
            isFocused = state.isFocused;
            setIsHasValue(state.hasValue);
            return {
              ...styles,
              border: "none",
              height: "100%",
              paddingRight: "1.6rem",
              paddingLeft: "2rem",
              borderRadius: isMobile ? "0.4rem" : "0.2rem",
              background:
                selectTheme === "white"
                  ? theme.colors.white
                  : theme.colors["pale-grey-global-bg"],
              boxShadow: isError
                ? `inset 0px 0px 0px 0.1rem ${theme.colors.error}`
                : "none",
              MozBoxShadow: isError
                ? `inset 0px 0px 0px 0.1rem ${theme.colors.error}`
                : "none",
              WebkitBoxShadow: isError
                ? `inset 0px 0px 0px 0.1rem ${theme.colors.error}`
                : "none",
            };
          },
          indicatorSeparator: (styles) => ({ ...styles, display: "none" }),
          dropdownIndicator: (styles) => ({
            ...styles,
            color: theme.colors["blue-grey"],
            paddingRight: "0",
          }),
          valueContainer: (styles, state) => ({
            ...styles,
            position: "static",
            overflowY: "visible",
            overflowX: "hidden",
            padding: "0",
            height: "100%",
            paddingTop:
              state.hasValue || state.selectProps.inputValue || isFocused
                ? placeholder
                  ? "2rem"
                  : "0"
                : "0",
          }),
          placeholder: (styles, state) => ({
            ...styles,
            position: "absolute",
            top:
              state.hasValue || state.selectProps.inputValue || isFocused
                ? "1rem"
                : "50%",
            transition: "all 0.3s",
            color: isError ? theme.colors.error : theme.colors["blue-grey"],
            fontSize:
              state.hasValue || state.selectProps.inputValue || isFocused
                ? "1.2rem"
                : isMobile
                ? "1.4rem"
                : "1.6rem",
            transform:
              state.hasValue || state.selectProps.inputValue || isFocused
                ? "translateY(0)"
                : "translateY(-50%)",
            marginLeft: 0,
          }),
          singleValue: (styles, state) => ({
            ...styles,
            transform:
              (state.hasValue || state.selectProps.inputValue || isFocused) &&
              "translateY(0)",
            top: placeholder ? "50%" : "auto",
            marginLeft: "0",
            marginRight: "0",
            maxWidth: "calc(100% - 6rem)",
          }),
          option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
            ...styles,
            padding: "0.6rem 2rem",
            background: "transparent !important",
            cursor: "pointer",
            fontSize: "1.6rem",
            lineHeight: "2rem",
            color:
              isSelected || isFocused
                ? theme.colors["electric-blue"]
                : theme.colors.black,
          }),
          menu: (styles, state) => ({
            ...styles,
            marginTop: "0.4rem",
            borderRadius: "0.2rem",
            boxShadow: "1px 1px 5px rgba(11, 11, 54, 0.12)",
          }),
          menuList: (styles, state) => ({
            ...styles,
            paddingTop: "1rem",
            paddingBottom: "1rem",
          }),
          noOptionsMessage: (styles) => ({
            ...styles,
            color: theme.colors.black,
            textAlign: "left",
          }),
          loadingMessage: (styles) => ({
            ...styles,
            color: theme.colors.black,
            textAlign: "left",
          }),
        }}
        textFieldProps={{
          label: "Label",
          InputLabelProps: {
            shrink: true,
          },
        }}
        defaultMenuIsOpen={defaultMenuIsOpen}
        noOptionsMessage={() => noOptionsValue}
      />
      {isClose && !isMobile && isHasValue && (
        <CloseIcon
          onClick={() => resetSetValue(name)}
          className={css.checkbox}
        />
      )}
      {isMobileMySelect && isMobile && (
        <select
          onChange={(e) => {
            if (e.target.value) {
              onChange(JSON.parse(e.target.value));
            }
          }}
          className={`absolute top-0 right-0 bottom-0 left-0 w-full opacity-0`}
          value={value ? JSON.stringify(value) : ""}
        >
          <option className={`hidden`} value=""></option>
          {options.map((item, i) => (
            <option key={i} value={JSON.stringify(item)}>
              {item.label}
            </option>
          ))}
        </select>
      )}
    </div>
  );
};

export default Select;

import { CategoryUserAssociationType, SupplierTPCategoryType } from "app/types";

export type TpCategoriesWithAssociationsType = {
  id: number;
  employeeName: string;
  tpCategoryId: number;
  tpCategoryName: string;
  userId: string;
};

// Создает объединенный массив с ТП категориями поставщика
// и текущими связками "категория-пользователь" для отрисовки в таблице
export const mergeTPCategoriesWithAssociations = (
  tpCategories: SupplierTPCategoryType[],
  associations: CategoryUserAssociationType[]
): TpCategoriesWithAssociationsType[] => {
  return tpCategories.map((category: SupplierTPCategoryType) => {
    const association: CategoryUserAssociationType = associations
      .find(({ tpCategoryId }) => tpCategoryId === category.id);

    const employeeName: string = association
      ? [association.userFirstName, association.userLastName, association.userEmail]
        .filter((item: string) => item)
        .join(" ")
      : null;

    return {
      id: association ? association.id : null,
      employeeName,
      tpCategoryId: category.id,
      tpCategoryName: category.tpCategoryName,
      userId: association ? association.userId : null,
    };
  });
};

import React, { FC, useEffect, useState } from "react";

import {
  deleteOrderDocument,
  getOrderDocuments,
} from "@/api/orders.api";
import { roleResolver } from "@/utils/roleResolver";
import { orderStatusResolver } from "@/utils/orderStatusResolver";
import { StatusNameOrderType } from "@/utils/statusNames";

import TableDocuments from "./TableDocuments";
import Button from "@/components/ui-kit/button";
import UploadFilesModal from "@/components/pages/platform/pages/DocumentManagement/UploadFilesModal/UploadFilesModal";
import SquareWithPlusIcon from "@/assets/icons/square-with-plus.inline.svg";

import css from "./index.modules.scss";

import { IDocument } from "app/types";
import { useSelector } from "react-redux";
import { AppStateType } from "@/reducers";
import EmptyResult from "@/components/ui-kit/emptyResult";

type DogumentManagementPropsType = {
  orderId: number,
  status: string,
  setUploadedDocuments?: (uploadedDocuments: IDocument[]) => void,
};

const DocumentManagement: FC<DogumentManagementPropsType> = ({
  orderId,
  status,
  setUploadedDocuments
}): JSX.Element => {
  const { userInfo } = useSelector((state: AppStateType) => state.account);

  const {
    isSupplier,
    isController,
  } = roleResolver(userInfo?.roles);

  const {
    isNew,
    isProcessing,
    isUpdated,
    isPreparation,
  } = orderStatusResolver(status as StatusNameOrderType);

  const [documents, setDocuments] = useState<IDocument[]>(null);
  const [selectedDocuments, setSelectedDocuments] = useState<number[]>([]);
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [canAddDocument, setCanAddDocument] = useState<boolean>(false);
  const [canDeleteDocument, setCanDeleteDocument] = useState<boolean>(false);

  useEffect(() => {
    orderId && fetchDocuments();
  }, [orderId]);

  useEffect(() => {
    const rolePermissions: Record<string, boolean> = {
      supplier: isSupplier && (isNew || isProcessing || isUpdated || isPreparation),
    };

    const isShow: boolean = Object.values(rolePermissions).some(Boolean);

    setCanAddDocument(isShow);
    setCanDeleteDocument(isShow);
  }, [status]);

  useEffect(() => {
    setUploadedDocuments(documents);
  }, [documents]);

  const fetchDocuments = (): void => {
    getOrderDocuments(orderId)
      .then((response) => {
        setDocuments(response.data);
      })
      .catch((error: Error) => {
        setDocuments([]);

        console.log(error);
      });
  };

  const deleteSelectedFiles = (): void => {
    const deletedIds: number[] = [];

    const promises: Promise<void>[] = selectedDocuments.map(async (id: number) => {
      try {
        await deleteOrderDocument(orderId, id);
        deletedIds.push(id);
      } catch (message) {
        return console.error(message);
      }
    });

    Promise.allSettled(promises)
      .then(() => {
        const updatedDocuments: IDocument[] = documents.filter((doc: IDocument) => !deletedIds.includes(doc.id));

        setDocuments(updatedDocuments);
      })
  };

  const openUploadModal = (): void => {
    !isController && setIsOpenModal(true);
  };

  const closeUploadModal = (): void => setIsOpenModal(false);

  return (
    <div className="mt-10 tablet:mt-15">
      <div className="flex flex-row justify-between">
        <div className={css.orderDetailsText}>Документооборот</div>
        <div className={css.tableActions}>
          {!!selectedDocuments?.length && canDeleteDocument && (
            <Button
              theme="secondary"
              className={css.button}
              onClick={deleteSelectedFiles}
            >
              Удалить выбранные
            </Button>
          )}
        </div>
      </div>
      <TableDocuments
        orderId={orderId}
        positions={documents}
        setPositions={(positions) => setDocuments(positions)}
        setSelectedDocuments={setSelectedDocuments}
        isEmpty={!documents?.length && !canAddDocument}
      />
      {canAddDocument && (
        <div className={`flex items-center mt-5 mobile:mt-10 ${css.squarePlusButton}`} onClick={openUploadModal}>
          <SquareWithPlusIcon />
          <span className="cursor-pointer ml-2 tablet:ml-1 uppercase text-n1">
            добавить документ
          </span>
        </div>
      )}
      {isOpenModal && (
        <UploadFilesModal
          orderId={orderId}
          isOpen={isOpenModal}
          onClose={closeUploadModal}
          fetchDocuments={fetchDocuments}
        />
      )}
    </div>
  );
};

export default DocumentManagement;

import React, { useState, FC, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import cn from "classnames";
import { navigate } from "gatsby";
import { Link, navigate as reach_navigate} from "@reach/router"
import { AnimatePresence, motion } from "framer-motion";
import useRootClose from "react-overlays/useRootClose";
import { globalHistory } from "@reach/router";

import { tokenRevocation } from "@/actions/account.actions";
import { AppStateType } from "@/reducers";
import usePageScroll from "@/hooks/usePageScroll";
import useMobile from "@/hooks/useMobile";
import { update } from "@/actions/search.actions";

import ListNotifications from "@/components/listNotifications";
import Button from "@/components/ui-kit/button";

import Purse from "@/assets/icons/purse.inline.svg";
import Profile from "@/assets/icons/profile.inline.svg";
import Cart from "@/assets/icons/cart.inline.svg";
import Notification from "@/assets/icons/notification.inline.svg";

import css from "./header.modules.scss";
import DeliveryCalculation from "@/components/deliveryCalculation";
import { getUserCartProducts } from "@/actions/cart.actions";
import { getNotificationsList } from "@/actions/notifications.actions";
import { roleResolver } from "@/utils/roleResolver";

const ProfileMenuItems = [
  {
    name: "Настройки",
    link: "/platform/settings/",
    newTab: false
  },
];

interface ISuperIconProps {
  name: string;
  isNotification?: boolean;
  className?: string;
  iconClassName?: string;
  count?: number;
  rest?: any[];
  onClick?: () => void;
}

const renderCount = (count: number): JSX.Element => {
  return (
    <div className={`${css.cartCount} text-n2`}>
      {count < 100 ? count : `${99}+`}
    </div>
  );
};

const SuperIcon: FC<ISuperIconProps> = ({
  name,
  isNotification,
  className,
  iconClassName,
  count = 0,
  onClick,
  ...rest
}) => {
  switch (name) {
    case "notification":
      return (
        <div
          className={cn(css.iconWrapper, className)}
          onClick={onClick}
        >
          <Notification className={`${css.icon} ${iconClassName}`} {...rest} />
          {!!count && renderCount(count)}
        </div>
      );
    case "cart":
      return (
        <div
          className={cn(css.iconWrapper, className, {
            [css.ellipse]: isNotification,
          })}
          onClick={() => navigate("/platform/cart")}
        >
          <Link
            getProps={
              ({ isPartiallyCurrent }) =>
                isPartiallyCurrent ? { className: css.linkActive } : {} // link active on child as well
            }
            to={"/platform/cart"}
            className={`${css.iconLink}`}
          >
            <Cart className={`${css.icon} ${iconClassName} relative`} {...rest} />
            {!!count && renderCount(count)}
          </Link>
        </div>
      );
    case "profile":
      return (
        <div
          className={cn(css.iconWrapper, className, {
            [css.ellipse]: isNotification,
          })}
        >
          <Profile className={`${css.icon} ${iconClassName}`} {...rest} />
        </div>
      );
    case "purse":
      return (
        <div
          className={cn(css.iconWrapper, className, {
            [css.ellipse]: isNotification,
          })}
        >
          <Link
            to={`/wallet`}
            className="border-none"
          >
            <Purse className={`${css.icon} ${iconClassName}`} {...rest} />
          </Link>
        </div>
      );
    default:
      return null;
  }
};

type HeaderPropsType = {
  isMenuOpen: boolean;
  toggleMenu: () => void;
  isNotification: boolean;
  setIsNotification: (boolean) => void;
};

const Header: FC<HeaderPropsType> = ({
  isMenuOpen,
  toggleMenu,
  isNotification,
  setIsNotification,
}) => {
  //const [inputSearch, setInputSearch] = useState(null);
  const [show, setShow] = useState(false);

  const ref = useRef();
  const handleRootClose = () => setShow(false);
  useRootClose(ref, handleRootClose, {
    disabled: !show,
  });

  const dispatch = useDispatch();
  const { userCart, updateProductStatus, removeProductStatus, addProductStatus } = useSelector((state: AppStateType) => state.cart);
  const { orderIds } = useSelector((state: AppStateType) => state.orders);
  const { count: notificationsCount } = useSelector((state: AppStateType) => state.notifications);
  const { userInfo } = useSelector((state: AppStateType) => state.account);

  const { isSupplier, isAuditor, isConcordant, isController } = roleResolver(userInfo?.roles);

  const isShowCartIcon: boolean = !isSupplier && !isAuditor && !isConcordant && !isController;

  useEffect(() => {
    if (userInfo && isShowCartIcon) {
      dispatch(getUserCartProducts())
    }
  }, [updateProductStatus, removeProductStatus, addProductStatus, orderIds, userInfo]);

  useEffect(() => {
    dispatch(getNotificationsList(1, 10, false));
  }, []);

  // useEffect(() => {
  //   let timer;
  //   if (inputSearch !== null) {
  //     timer = setTimeout(() => {
  //       dispatch(update("searchValue", inputSearch));
  //       reach_navigate("/platform/search");
  //     }, 1500);
  //   }
  //
  //   return () => {
  //     clearTimeout(timer);
  //   };
  // }, [inputSearch]);

  const isRoleChanged =
    typeof window !== "undefined"
      ? localStorage.getItem("isRoleChanged")
      : false;

  const { isMobile } = useMobile();
  const { isScrolled } = usePageScroll();

  const onLogout = () => {
    dispatch(tokenRevocation());
  };

  return (
    <div className="relative z-50">
      <button
        className={cn(
          `inline-flex items-center tablet:hidden cursor-pointer, ${css.menuWrapper}`,
          {
            [css.hiddenHeader]: isMobile && isScrolled,
          }
        )}
        onClick={toggleMenu}
        data-aos="fade-down"
        data-aos-once="true"
      >
        <div
          className={cn(css.menuBlock, {
            [css.menuActive]: isMenuOpen
              ? isMenuOpen
              : isMobile && isNotification,
          })}
        >
          <span className={`transition duration-300 delay-300 ${css.menuIcon}`}>
            <span
              className={`bg-black transition duration-300 delay-300 ${css.menuLine}`}
            ></span>
          </span>
        </div>
      </button>
      <header
        data-aos="fade-down"
        data-aos-once="true"
        className={cn(
          `px-3 tablet:px-8 bg-pale-grey-global-bg flex justify-between ${css.container}`,
          {
            [css.hiddenHeader]: isMobile && isScrolled,
          }
        )}
      >
        <button
          type="button"
          className={cn("text-s1", css.search, {
            [css.search_disable]:
            globalHistory.location.pathname === "/new/platform/search",
          })}
          onClick={() => {
            navigate("/platform/search");
          }}
        >
          Найти
        </button>

        <div className={`flex items-center`}>
          <div className={`flex items-center ${css.notifications}`}>
            {/* Кнопку помощи временно скрываем */}
            {/*<SuperIcon*/}
            {/*  name="help"*/}
            {/*  className={`${css.navItem} ${css.helpIcon}`}*/}
            {/*/>*/}
            <SuperIcon
              name="notification"
              count={notificationsCount ?? 0}
              isNotification={false}
              className={`${css.navItem} ${css.notificationIcon}`}
              iconClassName={cn(``, {
                [`cursor-auto	${css.navItem__disabled}`]:
                notificationsCount === 0,
              })}
              onClick={() =>
                notificationsCount > 0 &&
                setIsNotification((isPrev) => !isPrev)
              }
            />
            {isNotification && !isMobile && (
              <ListNotifications
                isNotification={isNotification}
                isShowCartIcon={isShowCartIcon}
                setIsNotification={setIsNotification}
              />
            )}
            {isShowCartIcon && (
              <SuperIcon
                name="cart"
                count={userCart?.totalItems || 0}
                className={`${css.navItem} ${css.basketIcon}`}
              />
            )}
            {!isMobile && (
              <div className={`${css.accountIconWrapper} ${css.navItem}`}>
                <button onClick={() => setShow(true)}>
                  <SuperIcon
                    name="profile"
                    iconClassName={cn({ [css.activeProfile]: show })}
                  />
                </button>
                <AnimatePresence>
                  {show && (
                    <motion.div
                      initial={{ opacity: 0 }}
                      animate={{
                        opacity: 1,
                        transition: { duration: 0.3 },
                      }}
                      exit={{
                        opacity: 0,
                        transition: { duration: 0.3 },
                      }}
                      className={css.profileMenu}
                      ref={ref}
                    >
                      <p className={`text-s1 mb-1`}>
                        {userInfo?.name}
                      </p>
                      <p
                        className={cn(`text-n2`, {
                          [`mb-4`]: !isRoleChanged,
                          [`mb-3`]: isRoleChanged,
                        })}
                      >
                        {userInfo?.email}
                      </p>
                      <ul className={`mb-6`}>
                        {ProfileMenuItems.map(({ name, link }, i) => (
                          <li key={i} className={`flex ${css.profileListItem}`}>
                            <a
                              href={link}
                              className={`text-s1 p-0 border-none ${css.profileLink}`}
                            >
                              {name}
                            </a>
                          </li>
                        ))}
                      </ul>
                      <Button
                        theme="secondary"
                        className={css.exitButton}
                        onClick={onLogout}
                      >
                        выйти
                      </Button>
                    </motion.div>
                  )}
                </AnimatePresence>
              </div>
            )}
          </div>
        </div>
      </header>

      <DeliveryCalculation />
    </div>
  );
};

export default Header;

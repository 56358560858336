import React, { FC, useEffect, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RouteComponentProps } from "@reach/router";
import {
  update,
  getSearchResult,
} from "@/actions/search.actions";
import { searchSelector } from "@/selectors/search.selector";

import Client from "./client";
import InputSearch from "@/components/ui-kit/inputSearch";
import Preloader from "@/components/ui-kit/preloader";

import css from "./search.modules.scss";

interface ISearch extends RouteComponentProps {}

const Search: FC<ISearch> = () => {
  const dispatch = useDispatch();

  const {
    results,
    searchValue,
    isFetching,
  } = useSelector(searchSelector);

  const [value, setValue] = useState<string>(searchValue);

  const onChange = useCallback((value: string) => {
    setValue(value);
    dispatch(update<string>("searchValue", value));
  }, []);

  const clearValue = useCallback(() => {
    setValue("");
    dispatch(update<string>("searchValue", ""));
  }, []);

  useEffect(() => {
    return () => {
      dispatch(update<string>("searchValue", ""));
      dispatch(update<[]>("results", []));
    };
  }, []);

  useEffect(() => {
    if (!searchValue) {
      dispatch(update<boolean>("isFetching", false));
      return;
    }

    let timer;

    dispatch(update<boolean>("isFetching", true));

    timer = setTimeout(() => {
      dispatch(
        getSearchResult({
          page: 1,
          pageSize: 3,
          path: "v1/orders",
          name: "Заказы",
          ordering: 1
        })
      )
    }, 400);

    return () => {
      clearTimeout(timer);
      dispatch(update<[]>("results", []));
    };
  }, [searchValue]);

  const renderClient = (): JSX.Element => {
    return (
      searchValue ? (
        <Client results={results} />
      ) : (
        <div className="text-x2 mt-8 tablet:mt-13">
          Введите Ваш запрос
        </div>
      )
    );
  };

  return (
    <div className={css.searchWrapper}>
      <InputSearch
        value={value}
        onChange={onChange}
        clearValue={clearValue}
        className={css.search}
      />
      {isFetching ? <Preloader /> : renderClient()}
    </div>
  );
};

export default Search;

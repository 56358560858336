import { STATUS_NAME_ORDER, StatusNameOrderType } from "./statusNames";

type OrderStatusFlags = {
  isNew: boolean;
  isProcessing: boolean;
  isUpdated: boolean;
  isCancelled: boolean;
  isClosed: boolean;
  isPreparation: boolean;
  isApproval: boolean;
};

export const orderStatusResolver = (status: StatusNameOrderType): OrderStatusFlags => {
  return {
    isNew: status === STATUS_NAME_ORDER.NEW,
    isProcessing: status === STATUS_NAME_ORDER.PROCESSING,
    isUpdated: status === STATUS_NAME_ORDER.UPDATED,
    isCancelled: status === STATUS_NAME_ORDER.CANCELLED,
    isClosed: status === STATUS_NAME_ORDER.CLOSED,
    isPreparation: status === STATUS_NAME_ORDER.PREPARATION,
    isApproval: status === STATUS_NAME_ORDER.APPROVAL,
  };
};

import {
  SEARCH_GET,
  SEARCH_GET_SUCCESS,
  SEARCH_GET_FAILURE,
  SEARCH_PAGINATION_UPDATE,
  SEARCH_CATALOG_GET,
  SEARCH_CATALOG_GET_SUCCESS,
  SEARCH_CATALOG_GET_FAILURE,
  SEARCH_UPDATE_ENTITY_VALUE,
  SEARCH_GET_SECTIONS_REQUEST,
  SEARCH_GET_SECTIONS_SUCCESS,
  SEARCH_GET_SECTIONS_FAILURE,
  SEARCH_GET_RESULT_REQUEST,
  SEARCH_GET_RESULT_SUCCESS,
  SEARCH_GET_RESULT_FAILURE,
  SEARCH_PAGINATION_REQUEST,
  SEARCH_PAGINATION_SUCCESS,
  SEARCH_PAGINATION_FAILURE,
  SEARCH_GET_FAQ_REQUEST,
  SEARCH_GET_FAQ_SUCCESS,
  SEARCH_GET_FAQ_FAILURE,
} from "@/constants/actionTypes";
import { ThunkAction } from "redux-thunk";
import { AppStateType } from "@/reducers";
import { SearchSectionType, SearchResultType, SearchFAQType } from "app/types";
import { getSearchResultType } from "app/params";
import * as SearchAPI from "@/api/search.api";

export type SearchActionsType =
  | GetRequestType
  | GetRequestSuccessType
  | GetRequestFailureType
  | SearchUpdateType
  | GetCatalogRequestType
  | GetCatalogRequestSuccessType
  | GetCatalogRequestFailureType
  | SearchResultRequestType
  | SearchResultSuccessType
  | SearchResultFailureType
  | SearchPaginationRequestType
  | SearchPaginationSuccessType
  | SearchPaginationFailureType
  | UpdateType;

type UpdateType<T = unknown> = {
  type: typeof SEARCH_UPDATE_ENTITY_VALUE;
  payload: {
    entity: string;
    value: T;
  };
};

export const update = <T = unknown>(
  entity: string,
  value: T
): UpdateType<T> => ({
  type: SEARCH_UPDATE_ENTITY_VALUE,
  payload: { entity, value },
});

type GetRequestSuccessType = {
  type: typeof SEARCH_GET_SUCCESS;
  payload: {
    count: number;
    next: string;
    previous: string | null;
    results: any;
  };
};

export const getSearchSuccess = (
  data: GetRequestSuccessType["payload"]
): GetRequestSuccessType => ({
  type: SEARCH_GET_SUCCESS,
  payload: data,
});

type GetRequestFailureType = {
  type: typeof SEARCH_GET_FAILURE;
  payload: {
    err: string;
  };
};

export const getSearchFailure = (err: string): GetRequestFailureType => ({
  type: SEARCH_GET_FAILURE,
  payload: {
    err,
  },
});

type GetRequestType = {
  type: typeof SEARCH_GET;
  payload: {
    page?: number;
    page_size?: number;
    search?: number | string;
  };
};

export const getSearch = ({
  page,
  page_size,
  search,
}: GetRequestType["payload"]): ThunkAction<
  Promise<void>,
  AppStateType,
  undefined,
  SearchActionsType
> => async (dispatch) => {
  try {
    const response: GetRequestSuccessType["payload"] = await SearchAPI.search(
      page,
      page_size,
      search
    );
    if (response) {
      dispatch(getSearchSuccess(response));
    }
  } catch (err) {
    console.log(err);
    dispatch(getSearchFailure(err));
  }
};

type GetCatalogRequestType = {
  type: typeof SEARCH_CATALOG_GET;
};

const getCatalogRequest = (): GetCatalogRequestType => ({
  type: SEARCH_CATALOG_GET,
});

type GetCatalogRequestSuccessType = {
  type: typeof SEARCH_CATALOG_GET_SUCCESS;
  payload: {
    count: number;
    next: string;
    previous: string | null;
    results: any;
  };
};

const getSearchCatalogSuccess = (
  data: GetCatalogRequestSuccessType["payload"]
): GetCatalogRequestSuccessType => ({
  type: SEARCH_CATALOG_GET_SUCCESS,
  payload: data,
});

type GetCatalogRequestFailureType = {
  type: typeof SEARCH_CATALOG_GET_FAILURE;
  payload: {
    err: string;
  };
};

const getSearchCatalogFailure = (
  err: string
): GetCatalogRequestFailureType => ({
  type: SEARCH_CATALOG_GET_FAILURE,
  payload: {
    err,
  },
});

export const getSearchCatalog = ({
  page,
  page_size,
  search,
}: GetRequestType["payload"]): ThunkAction<
  Promise<void>,
  AppStateType,
  undefined,
  SearchActionsType
> => async (dispatch) => {
  dispatch(getCatalogRequest());
  try {
    const response: GetRequestSuccessType["payload"] = await SearchAPI.searchProductDetailed(
      page,
      page_size,
      search
    );
    if (response) {
      dispatch(getSearchCatalogSuccess(response));
    }
  } catch (err) {
    console.log(err);
    dispatch(getSearchCatalogFailure(err));
  }
};

type SearchUpdateType = {
  type: typeof SEARCH_PAGINATION_UPDATE;
  payload: {
    entitySearch: string;
    valueSearch: number;
  };
};

export const searchPagination = (
  entitySearch: string,
  valueSearch: number
): SearchUpdateType => ({
  type: SEARCH_PAGINATION_UPDATE,
  payload: {
    entitySearch,
    valueSearch,
  },
});

type SearchResultRequestType = {
  type: typeof SEARCH_GET_RESULT_REQUEST;
};

const searchResultRequest = (): SearchResultRequestType => ({
  type: SEARCH_GET_RESULT_REQUEST,
});

type SearchResultSuccessType = {
  type: typeof SEARCH_GET_RESULT_SUCCESS;
  payload: { data: SearchResultType };
};

const searchResultSuccess = (
  data: SearchResultType
): SearchResultSuccessType => ({
  type: SEARCH_GET_RESULT_SUCCESS,
  payload: { data },
});

type SearchResultFailureType = {
  type: typeof SEARCH_GET_RESULT_FAILURE;
  payload: { err: string; path: string; name: string; ordering: number };
};

const searchResultFailure = (
  data: SearchResultFailureType["payload"]
): SearchResultFailureType => ({
  type: SEARCH_GET_RESULT_FAILURE,
  payload: data,
});

export const getSearchResult = (
  params: getSearchResultType
): ThunkAction<
  Promise<void>,
  AppStateType,
  undefined,
  SearchActionsType
> => async (dispatch, getState) => {
  const { searchValue } = getState().search;
  const { page, pageSize, path, name, ordering } = params;
  dispatch(searchResultRequest());
  try {
    const response = await SearchAPI.getSearchResult({
      page,
      pageSize,
      path,
      search: searchValue ? searchValue : null,
    });
    dispatch(
      searchResultSuccess({
        ...response,
        name,
        ordering,
        path,
        page,
        pageSize,
        isFetching: false,
      })
    );
  } catch (err) {
    dispatch(searchResultFailure({ err, path, name, ordering }));
  }
};

type SearchPaginationRequestType = {
  type: typeof SEARCH_PAGINATION_REQUEST;
  payload: { nameRequest: string };
};

const searchPaginationRequest = (
  nameRequest: string
): SearchPaginationRequestType => ({
  type: SEARCH_PAGINATION_REQUEST,
  payload: { nameRequest },
});

type SearchPaginationSuccessType = {
  type: typeof SEARCH_PAGINATION_SUCCESS;
  payload: {
    results: SearchResultType;
  };
};

const searchPaginationSuccess = (
  results: SearchResultType
): SearchPaginationSuccessType => ({
  type: SEARCH_PAGINATION_SUCCESS,
  payload: { results },
});

type SearchPaginationFailureType = {
  type: typeof SEARCH_PAGINATION_FAILURE;
  payload: {
    err: string;
    nameFailure: string;
  };
};

const searchPaginationFailure = (
  err: string,
  nameFailure: string
): SearchPaginationFailureType => ({
  type: SEARCH_PAGINATION_FAILURE,
  payload: { err, nameFailure },
});

export const getSearchPagination = (
  name: string
): ThunkAction<
  Promise<void>,
  AppStateType,
  undefined,
  SearchActionsType
> => async (dispatch, getState) => {
  const { results, searchValue } = getState().search;
  dispatch(searchPaginationRequest(name));
  try {
    const result = results.filter((r) => r.name === name)[0];
    const { path, pageSize } = result;
    const page = result.page + 1;
    const response = await SearchAPI.getSearchResult({
      path,
      page,
      pageSize,
      search: searchValue ? searchValue : null,
    });
    const data = { ...result, ...response, page, isFetching: false };

    dispatch(searchPaginationSuccess(data));
  } catch (err) {
    dispatch(searchPaginationFailure(err, name));
  }
};

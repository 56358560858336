export const STATUS_NAME_ORDER = {
  NEW: "new",
  UPDATED: "updated",
  PROCESSING: "processing",
  CANCELLED: "cancelled",
  CLOSED: "closed",
  PREPARATION: "preparation",
  APPROVAL: "approval",
} as const;

type StatusNameOrderKeysType = keyof typeof STATUS_NAME_ORDER;
export type StatusNameOrderType = (typeof STATUS_NAME_ORDER)[StatusNameOrderKeysType];

const statusNameMap: Record<StatusNameOrderType, string> = {
  [STATUS_NAME_ORDER.NEW]: "Новый заказ",
  [STATUS_NAME_ORDER.UPDATED]: "Согласование изменений",
  [STATUS_NAME_ORDER.PROCESSING]: "В обработке",
  [STATUS_NAME_ORDER.CANCELLED]: "Отменён",
  [STATUS_NAME_ORDER.CLOSED]: "Выполнен",
  [STATUS_NAME_ORDER.PREPARATION]: "Подготовка КП",
  [STATUS_NAME_ORDER.APPROVAL]: "На согласовании КП",
};

export const statusNameOrder = (statusName: StatusNameOrderType): string => {
  return statusNameMap[statusName] || "";
};
import { createSelector } from "reselect";
import { AppStateType } from "@/reducers";
import { InitialStateType as SearchStateType } from "@/reducers/search.reducer";
import { InitialStateType as AccountStateType } from "@/reducers/account.reducer";

type SearchObj = {
  results: any[];
  searchValue: string | null;
  isFetching: boolean;
};

export const searchSelector = createSelector(
  (state: AppStateType): AccountStateType => state.account,
  (state: AppStateType): SearchStateType => state.search,
  (account, search): SearchObj => {

    const {
      results,
      searchValue,
      isFetching,
    } = search;

    return {
      results,
      searchValue,
      isFetching,
    };
  }
);

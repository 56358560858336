type UserRoleFlagsType = {
  isSupplier: boolean;
  isPurchaser: boolean;
  isAuditor: boolean;
  isController: boolean;
  isConcordant: boolean;
  isDemo: boolean;
  isUnconfirmed: boolean;
};

export const roleResolver = (roles: string[]): UserRoleFlagsType =>  {
  return {
    isSupplier: roles?.includes("Поставщик"),
    isPurchaser: roles?.includes("Заказчик"),
    isAuditor: roles?.includes("Аудитор"),
    isController: roles?.includes("Контролёр") || roles?.includes("Контролер"),
    isConcordant: roles?.includes("Согласовант"),
    isDemo: roles?.includes("Демо"),
    isUnconfirmed: roles?.includes("Неверифицированный пользователь")
  };
};

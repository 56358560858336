import React, { FC, useState, useCallback, useMemo, useEffect } from "react";
import { useDispatch } from "react-redux";
import cn from "classnames";
import { SearchResultType } from "app/types";
import {
  getSearchPagination,
} from "@/actions/search.actions";
import css from "./client.modules.scss";
import NoResult from "../noresult";
import OrderCard from "@/components/orderCard";
import ProductCard from "@/components/productCard";
import BidCard from "@/components/bidCard";
import SearchItem from "@/components/searchItem";
import SuppliersCard from "@/components/suppliersCard";
import Description from "@/components/suppliersCard/description";
import ProductCardProvider from "@/components/ProductCardProvider";
import DocumentFlow from "@/components/documentFlow";
import TabButton from "@/components/ui-kit/tabButton";

const cx = cn.bind(css);

type ClientTypeProps = {
  results: SearchResultType[];
};

enum TAB_BUTTON_CAP {
  ALL = "",
  ORDERS = "v1/orders",
}

const Client: FC<ClientTypeProps> = ({
  results,
}) => {
  const dispatch = useDispatch();

  const [tabButtonCap, setTabButtonCap] = useState<string>(TAB_BUTTON_CAP.ORDERS);
  const [tabButtonCaps, setTabButtonCaps] = useState<string[]>([]);

  const onTabButtonCap = (value: string) => setTabButtonCap(value);
  const filterPath = (path: string) =>
    results.filter((r) => r.path === path)[0];

  useEffect(() => {
    if (results.length) {
      setTabButtonCaps(results.map(({ path }) => path));
    }
  }, [results]);

  const onPagination = (name: string) => dispatch(getSearchPagination(name));

  const renderOrders = useCallback(
    ({ name, page, pages, isFetching, records }): JSX.Element => (
      <SearchItem
        title={name}
        page={page}
        pages={pages}
        isFetching={isFetching}
        classNameIdent={css.indent}
        onClick={() => onPagination(name)}
      >
        {records?.map((o) => {
          return <OrderCard key={o.id} item={o} />;
        })}
      </SearchItem>
    ),
    []
  );

  const renderSwitchResult = useCallback(
    (r: SearchResultType) => {
      const caseName = r ? r.path : "";
      switch (caseName) {
        case TAB_BUTTON_CAP.ORDERS:
          return !!r.pages && renderOrders(r);
        default:
          return null;
      }
    },
    []
  );

  const totalCount = useMemo(
    () => results.reduce((acc, res) => acc + res.pages, 0),

    [results]
  );

  const renderTabs = useMemo((): JSX.Element[] =>
    results.map(({ pages, ordering, path, name }) =>
      !!pages && (
        <TabButton
          key={ordering}
          className={css.tabButtonCap}
          onClick={() => onTabButtonCap(path)}
          isActive={tabButtonCaps.find((t: string) => t === tabButtonCap) === path}
        >
          {name}
        </TabButton>
      )
    ),
    [results, tabButtonCap, tabButtonCaps]
  );

  const renderSearchCase = useMemo((): JSX.Element | JSX.Element[] => {
    switch (tabButtonCap) {
      case TAB_BUTTON_CAP.ORDERS:
        const order = filterPath(TAB_BUTTON_CAP.ORDERS);

        return renderSwitchResult(order);
      default:
        return results.map((r) => renderSwitchResult(r));
    }
  }, [results, tabButtonCap, tabButtonCaps, filterPath]);

  if (!!totalCount)
    return (
      <>
        <div>
          <div
            className={`flex justify-between mb-5 tablet:mb-8 overflow-auto px-6 tablet:px-8 -mx-6 tablet:-mx-8 ${css.tabsWrapper}`}
          >
            <div className="flex justify-start items-center px-6 tablet:px-8 -mx-6 tablet:-mx-8">
              {/*TODO: пока есть только заказы, поэтому скрываем вкладку "Все результаты"*/}
              {/*<TabButton*/}
              {/*  className={css.tabButtonCap}*/}
              {/*  onClick={() => onTabButtonCap("")}*/}
              {/*  isActive={tabButtonCap === TAB_BUTTON_CAP.ALL}*/}
              {/*>*/}
              {/*  Все результаты*/}
              {/*</TabButton>*/}
              {renderTabs}
            </div>
          </div>
        </div>
        {renderSearchCase}
      </>
    );

  return <NoResult />;
};

export default Client;

import React, { FC } from "react";
import dayjs from "dayjs";
import { OrderCommentType } from "app/types";

interface ICommentProps {
  commentItem: OrderCommentType;
}

const Comment: FC<ICommentProps> = ({ commentItem }): JSX.Element => {
  const { createdAt, creatorsName, comment, commentSubject } = commentItem;

  return (
    <div className="bg-white text-n1 w-full p-4 mb-2 tablet:p-6">
      <div className="flex text-blue-grey">
        <p className="mr-2">{dayjs(createdAt).format("DD.MM.YYYY HH:mm")}</p>
        {commentSubject === "order_revision" && (
          <p className="mr-2 text-light-carmine-pink">Отправлено на доработку</p>
        )}
        <p className="mr-2">{creatorsName}</p>
      </div>
      <p className="mt-1 whitespace-pre-wrap">{comment}</p>
    </div>
  );
};

export default Comment;

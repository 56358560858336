import axios from "@/utils/axios";
import { UploadFileType } from "app/types";

const API_BASE: string = process.env.GATSBY_API_BASE?.trimEnd();

export const importCategoriesFile = async (file: File|UploadFileType) => {
  return axios.POST_FOR_IMPORT(API_BASE, "/api/iss/v1/categories/import", {
    file: file,
  });
};

export const getTpCategories = async (maxLevel?: number, category_id?: number) => {
  return axios.GET(API_BASE, "/api/iss/v1/tp-categories", { maxLevel, category_id });
};

// Листинг связки категория-пользователь
export const getCategoryUserAssociation = async () => {
  return axios.GET(API_BASE, "/api/iss/v1/tp-categories/category_user_association");
};

// Создание связки категория-пользователь
export const createCategoryUserAssociation = async (tpCategoryId: number, userId: string) => {
  return axios.POST(API_BASE, `/api/iss/v1/tp-categories/category_user_association?tpCategoryId=${tpCategoryId}&userId=${userId}`);
};

// Обновление связки категория-пользователь
export const updateCategoryUserAssociation = async (associationId: number, tpCategoryId: number, userId: string) => {
  return axios.PUT(API_BASE, `/api/iss/v1/tp-categories/category_user_association/${associationId}`, {
    userId,
    tpCategoryId,
  });
};

// Удаление связки категория-пользователь
export const deleteCategoryUserAssociation = async (associationId: number) => {
  return axios.DELETE(API_BASE, `/api/iss/v1/tp-categories/category_user_association/${associationId}`);
};

import React, {
  FC,
  useRef,
  useMemo,
  useCallback,
  useEffect,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppStateType } from "@/reducers";
import { getNotificationsList, readNotifications } from "@/actions/notifications.actions";
import { NotificationListType } from "app/types";

import { AnimatePresence, motion } from "framer-motion";
import useRootClose from "react-overlays/useRootClose";
import dayjs from "dayjs";

import useMobile from "@/hooks/useMobile";

import Common from "./common";
import { Pagination, PaginationProps } from "antd";

import css from "./listNotifications.modules.scss";

type PropsNotificationListType = {
  isNotification: boolean;
  isShowCartIcon: boolean;
  setIsNotification: (boolean) => void;
};

const ListNotifications: FC<PropsNotificationListType> = ({
  isNotification,
  isShowCartIcon,
  setIsNotification,
}) => {
  const ref = useRef();
  const dispatch = useDispatch();
  const handleRootClose = () => setIsNotification(false);

  useRootClose(ref, handleRootClose, {
    disabled: !isNotification,
  });

  const {
    notifications,
    count: notificationsCount,
    page,
    pages,
    pageSize,
  } = useSelector((state: AppStateType) => state.notifications);

  const { isMobile } = useMobile();

  const maxLengthString = isMobile ? 44 : 34;

    useEffect(() => {
      if (!notifications || notificationsCount === 0) {
        handleRootClose();
      }
    }, [notifications, notificationsCount]);

  const onReadNotification = useCallback(async (id: number): Promise<void> => {
    await dispatch(readNotifications(id));
    await dispatch(getNotificationsList(page, pageSize, false));
  }, []);

  const paginationChange: PaginationProps['onChange'] = (pageNumber: number): void => {
    dispatch(getNotificationsList(pageNumber, 10, false));
  };

  const renderLists = useMemo(
    () =>
      notifications.map(
        ({
           id,
           isRead,
           message,
           sentAt,
           redirectUrl,
         }) => (
          <div className="flex flex-col relative overflow-x-hidden" key={id}>
            <div className={css.hover}>
              <Common
                id={id}
                message={message}
                redirectUrl={redirectUrl}
                createdAt={dayjs(sentAt).format("DD.MM.YYYY HH:mm")}
                isRead={isRead}
              />
            </div>
            {!isRead && (
              <div
                className="text-n1 my-2 cursor-pointer"
                onClick={() => onReadNotification(id)}
              >
                <span className={`p-2 ${css.hover}`}>ПОМЕТИТЬ КАК ПРОЧИТАНО</span>
              </div>
            )}
            <div className="mt-3 mb-5 tablet:pl-3 tablet:mt-2 tablet:mb-3">
              <hr className={css.hr} />
            </div>
          </div>
        )
      ),
    [notifications, isMobile, maxLengthString]
  );

  return (
    <div className={css.content}>
      {isMobile ? (
        <div ref={ref}>{renderLists}</div>
      ) : (
        <AnimatePresence>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{
              opacity: 1,
              transition: { duration: 0.3 },
            }}
            exit={{
              opacity: 0,
              transition: { duration: 0.3 },
            }}
            className={`${css.profileMenu} ${isShowCartIcon ? css.notificationsForCart : css.baseNotifications}`}
            ref={ref}
          >
            <div className={css.sideBarScrollable}>
              {renderLists}
            </div>
            <div className="notifications-pagination mt-3">
              {notificationsCount > 0 && (
                <Pagination
                  style={{ color: "#FFFFFF" }}
                  total={pages * 10}
                  onChange={paginationChange}
                  defaultCurrent={page}
                  showSizeChanger={false}
                  hideOnSinglePage
                />
              )}
            </div>
          </motion.div>
        </AnimatePresence>
      )}
    </div>
  );
};

export default ListNotifications;

import React, { FC } from "react";

import { NotificationListType } from "app/types";
import Button from "@/components/ui-kit/button";
import Info from "@/assets/icons/info.inline.svg";
import css from "./importantCard.modules.scss";
import dayjs from "dayjs";

type ImportantCardPropsType = {
  data: NotificationListType;
  containerClassName?: string;
};

const ImportantCard: FC<ImportantCardPropsType> = ({
  data,
  containerClassName,
}) => {
  const handleClick = () => window.open(data.redirectUrl, "_blank");

  return (
    <div className={`flex flex-col justify-between w-full ${css.item} ${containerClassName}`}>
      <div>
        <div className={`flex justify-between items-center tablet:absolute ${css.iconWrapper}`}>
          <Info className={css.icon} />
          <span className="text-n2 text-blue-grey">{dayjs(data.sentAt).format("DD.MM.YYYY HH:mm")}</span>
        </div>
        <div className={`mt-0 ${css.headerText} tablet:mt-12 leading-10 font-medium text-s1`}>
          {data?.message}
        </div>
      </div>
      <Button className={`hidden bg-white tablet:block ${css.button}`} onClick={handleClick}>
        Смотреть
      </Button>
    </div>
  );
};

export default ImportantCard;

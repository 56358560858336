import React, {
  FC,
  Dispatch,
  HTMLAttributes,
  PropsWithChildren,
  SetStateAction,
  useEffect,
  useState
} from "react";
import { Form, FormInstance, message, Popconfirm, Select, Table, TableProps, Typography } from "antd";
import * as CategoriesAPI from "@/api/categories.api";
import { useDispatch, useSelector } from "react-redux";
import { AppStateType } from "@/reducers";
import { fetchSupplierTPCategories, fetchSupplierUsers } from "@/actions/suppliers.actions";
import {
  mergeTPCategoriesWithAssociations,
  TpCategoriesWithAssociationsType,
} from "@/components/pages/platform/pages/settings/DistributionOfOrders/mergeTPCategoriesWithAssociations";
import { fetchCategoryUserAssociation } from "@/actions/categories.actions";
import { SelectDataType } from "app/types";
import { AxiosResponse } from "axios";

type ColumnType = {
  title: string;
  dataIndex: string;
  width: string;
  editable?: boolean;
  render?: (_: any, record: TpCategoriesWithAssociationsType) => JSX.Element;
};

interface IDistributionOfOrdersProps {}

interface EditableCellProps extends HTMLAttributes<HTMLElement> {
  form: FormInstance;
  editing: boolean;
  dataIndex: string;
  record: TpCategoriesWithAssociationsType;
  setSelectedValue: Dispatch<SetStateAction<string>>;
  children: JSX.Element;
}

const EditableCell: FC<PropsWithChildren<EditableCellProps>> = ({
  form,
  editing,
  dataIndex,
  record,
  children,
  setSelectedValue,
}) => {
  const { supplierUsers } = useSelector((state: AppStateType) => state.suppliers);

  const [options, setOptions] = useState<SelectDataType[]>(null);

  useEffect(() => {
    if (supplierUsers) {
      setOptions(supplierUsers.map(({ id, email, firstName, lastName }) => (
        { label: `${firstName} ${lastName} ${email}`, value: id }
      )));
    }
  }, [supplierUsers]);

  const renderChildren: JSX.Element = dataIndex === "employee" && !record.employeeName
    ? <span className="text-blue-grey">Сотрудник не назначен</span>
    : children;

    const handleOnChange = (value: string): void => {
      setSelectedValue(value);
      form.setFieldsValue({ employee: value });
    };

  return (
    <td style={{ width: "100%" }}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0 }}
          valuePropName="value"
        >
          <Select
            placeholder="Выберите сотрудника"
            options={options}
            onChange={handleOnChange}
          />
        </Form.Item>
      ) : (
        renderChildren
      )}
    </td>
  );
};

const DistributionOfOrders: FC<IDistributionOfOrdersProps> = ({}): JSX.Element => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const { Text } = Typography;

  const { userInfo } = useSelector((state: AppStateType) => state.account);
  const { suppliersTPCategories } = useSelector((state: AppStateType) => state.suppliers);
  const { userAssociations } = useSelector((state: AppStateType) => state.categories);

  const { supplier_id } = userInfo;

  const [editingKey, setEditingKey] = useState<string>("");
  const [tpCategoriesWithAssociations, setTpCategoriesWithAssociations] = useState<TpCategoriesWithAssociationsType[]>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedValue, setSelectedValue] = useState<string>(null);

  const isEditing = (record: TpCategoriesWithAssociationsType): boolean => record.tpCategoryId?.toString() === editingKey;

  useEffect(() => {
    if (supplier_id) {
      fetchCategoriesData();
      dispatch(fetchSupplierUsers(+supplier_id));
    }
  }, [supplier_id]);

  useEffect(() => {
    if (suppliersTPCategories?.length) {
      setTpCategoriesWithAssociations(mergeTPCategoriesWithAssociations(suppliersTPCategories, userAssociations ?? []));
    }
  }, [suppliersTPCategories?.length, userAssociations]);

  useEffect(() => {
    if (isLoading && tpCategoriesWithAssociations) {
      setIsLoading(false);
    }
  }, [tpCategoriesWithAssociations]);

  const fetchCategoriesData = (): void => {
    setIsLoading(true);

    dispatch(fetchSupplierTPCategories(+supplier_id));
    dispatch(fetchCategoryUserAssociation());
  };

  const columns: ColumnType[] = [
    {
      title: "Категория",
      dataIndex: "tpCategoryName",
      width: "40%",
      editable: false,
    },
    {
      title: "Сотрудник",
      dataIndex: "employee",
      width: "40%",
      editable: true,
      render: (_: any, record: TpCategoriesWithAssociationsType): JSX.Element => (
        <div className="flex">
          <EditableCell
            editing={isEditing(record)}
            dataIndex="employee"
            record={record}
            form={form}
            children={<>{record.employeeName}</>}
            setSelectedValue={setSelectedValue}
        />
        </div>
      ),
    },
    {
      title: "Действие",
      dataIndex: "action",
      width: "20%",
      render: (_: any, record: TpCategoriesWithAssociationsType): JSX.Element => renderEditControls(record)
    },
  ];

  const renderEditControls = (record: TpCategoriesWithAssociationsType): JSX.Element => {
    const editable: boolean = isEditing(record);

    const handleOnSave = (): void => {
      if (selectedValue) {
        !record?.userId
          ? createAssociation(record.tpCategoryId, selectedValue)
          : updateAssociation(record.id, record.tpCategoryId, selectedValue);
      }
    };

    const handleDelete = (): Promise<void> => removeAssociation(record.id);
    const handleEdit = (): void => edit(record);

    return editable ? (
      <div className="flex">
        <Text
          onClick={handleOnSave}
          style={{ marginInlineEnd: 8 }}
          className="text-electric-blue cursor-pointer"
          disabled={!selectedValue}
        >
          Сохранить
        </Text>
        <Popconfirm
          title={<>Вы уверены, что хотите <br/>отменить действие?</>}
          onConfirm={handleDelete}
          cancelText="Нет"
          okText="Да"
          disabled={!selectedValue || !record?.userId}
        >
          <Text
            className="text-electric-blue cursor-pointer"
            disabled={!selectedValue || !record?.userId}
          >
            Отменить
          </Text>
        </Popconfirm>
      </div>
    ) : (
      <p onClick={handleEdit} className="text-electric-blue cursor-pointer">
        Изменить
      </p>
    );
  };

  const mergedColumns: TableProps<TpCategoriesWithAssociationsType>["columns"] = columns.map((col: ColumnType) => {
    if (!col.editable) {
      return col;
    }
    
    return {
      ...col,
      onCell: (record: TpCategoriesWithAssociationsType) => ({
        record,
        inputType: col.dataIndex === "employee",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  const edit = (record: TpCategoriesWithAssociationsType): void => {
    form.setFieldsValue({ employee: record.userId });
    setSelectedValue(record.userId);
    setEditingKey(record.tpCategoryId?.toString());
  };

  const handleAssociationAction = async (action: Promise<any>, errorMessage: string): Promise<void> => {
    await action
      .then(() => {
        fetchCategoriesData();
        setEditingKey("");
      })
      .catch(() => message.error(errorMessage));
  };

  const createAssociation = async (tpCategoryId: number, userId: string): Promise<void> => {
    const action: Promise<AxiosResponse> = CategoriesAPI.createCategoryUserAssociation(tpCategoryId, userId);

    await handleAssociationAction(action, "Не удалось связать пользователя с категорией");
  };

  const removeAssociation = async (associationId: number): Promise<void> => {
    const action: Promise<AxiosResponse> = CategoriesAPI.deleteCategoryUserAssociation(associationId);

    await handleAssociationAction(action, "Не удалось отвязать пользователя от категории");
  };

  const updateAssociation = async (associationId: number, tpCategoryId: number, userId: string): Promise<void> => {
    const action: Promise<AxiosResponse> = CategoriesAPI.updateCategoryUserAssociation(associationId, tpCategoryId, userId);

    await handleAssociationAction(action, "Не удалось изменить пользователя");
  };

  return (
    <div className="bg-white p-4">
      <Form form={form} component={false}>
        <Table
          columns={mergedColumns}
          loading={isLoading}
          dataSource={tpCategoriesWithAssociations}
          locale={{ emptyText: "Не привязано ни одной категории" }}
        />
      </Form>
    </div>
  );
};

export default DistributionOfOrders;

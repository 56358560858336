import React, { FC } from "react";

import BottomButtonCatalog from "@/components/ui-kit/bottomButtonCatalog";
import Preloader from "@/components/ui-kit/preloader";

import css from "./searchItem.modules.scss";

type SearchItemProps = {
  title: string;
  page: number;
  pages: number;
  isFetching: boolean;
  children: React.ReactNode;
  classNameIdent: string;
  onClick: () => void;
};

const SearchItem: FC<SearchItemProps> = ({
  page,
  pages,
  isFetching,
  children,
  classNameIdent,
  onClick,
  title = "",
}) => (
  <div className={css.container}>
    <span className={css.title}>
      {title}
    </span>
    <div className={classNameIdent}>
      {children}
      {isFetching ? (
        <Preloader />
      ) : (
        page < pages && (
          <div className={css.showMore} onClick={onClick}>
            <BottomButtonCatalog />
          </div>
        )
      )}
    </div>
  </div>
);

export default SearchItem;

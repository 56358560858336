import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Router, navigate as reach_navigate } from "@reach/router";

import {
  decodeToken,
  fetchUserInfo,
  tokenRevocation,
} from "@/actions/account.actions";
import { AppStateType } from "@/reducers";

import SEO from "@/components/seo";
import LayoutPlatform from "@/components/pages/platform/layout";
import PlatformIndex from "@/components/pages/platform/pages/index";
import Cart from "@/components/pages/platform/pages/cart";
import Checkout from "@/components/pages/platform/pages/checkout";
import Search from "@/components/pages/platform/pages/search";
import { withPrefix } from "gatsby";
import CardOrder from "@/components/pages/platform/pages/CardOrder";
import Settings from "@/components/pages/platform/pages/settings/Settings";
import { Suppliers } from "@/components/pages/platform/pages/suppliers";
import Catalog from "@/components/pages/platform/pages/Catalog/Catalog";
import CatalogPage from "@/components/pages/platform/pages/Catalog/CatalogPage/CatalogPage";
import CatalogVerificationPage
  from "@/components/pages/platform/pages/Catalog/CatalogVerificationPage/CatalogVerificationPage";
import CatalogProductCard
  from "@/components/pages/platform/pages/Catalog/CatalogPage/CatalogProductCard/CatalogProductCard";
import OrdersPage from "@/components/pages/platform/pages/index/blocks/OrderBlock/OrdersPage";
import { AboutCompany } from "@/components/pages/platform/pages/settings/AboutCompany/AboutCompany";
import UserProfile from "@/components/pages/platform/pages/settings/UserProfile/UserProfile";
import { roleResolver } from "@/utils/roleResolver";
import Reports from "@/components/pages/platform/pages/Reports/Reports";
import SupplierCard from "@/components/pages/platform/pages/suppliers/SupplierCard/SupplierCard";
import { ConfigProvider, message } from "antd";
import ruRU from "antd/lib/locale/ru_RU";
import dayjs from "dayjs";
import dayjs_ru from "dayjs/locale/ru";

const Platform = () => {
  const { userInfo, authtoken, portalSettingsData } = useSelector((state: AppStateType) => state.account);
  const { tpCategory, tpCurrentCategory, tpCategoryLvlOne } = useSelector((state: AppStateType) => state.categories);
  const dispatch = useDispatch();

  const { isAuditor, isPurchaser, isUnconfirmed } = roleResolver(userInfo?.roles);

  dayjs.locale(dayjs_ru); // Локализация для antd

  // В этом хуке обновляем заголовок сайта
  useEffect(() => {
    if (!!portalSettingsData) {
      document.title = portalSettingsData?.portalName;
    }
  }, [portalSettingsData]);

  useEffect(() => {
    if (authtoken) {
      dispatch(decodeToken(authtoken));
      dispatch(fetchUserInfo());
      // TODO: выяснить, нужен ли этот запрос, если нет, то удалить
      // dispatch(getPortalSettingsData());
    }

    if (!authtoken) {
      reach_navigate("/login");
    }

  }, [authtoken]);

  useEffect(() => {
    if (userInfo && (!userInfo.email_verified || isUnconfirmed)) {
      dispatch(tokenRevocation());
      message.warning("Учетная запись ожидает подтверждения администратором", 5);
    }
  }, [userInfo]);

  const categoryLinks = useMemo(() => {
    if (!!tpCategory && !!tpCategoryLvlOne && tpCurrentCategory) {
      return [
        {
          name: "Каталог",
          link: "/platform/catalog/" + tpCurrentCategory.id,
          options: tpCategoryLvlOne.children,
          entity: "category"
        },
        {
          name: tpCurrentCategory.name,
          link: "/platform/catalog/" + tpCurrentCategory.id,
          options: tpCurrentCategory.children,
          entity: "currentCategory"
        }
      ];
    }

    return [];
  }, [tpCategory, tpCategoryLvlOne, tpCurrentCategory]);

  const showSuppliersList: boolean = isPurchaser || isAuditor;

  return (
    <>
      <SEO />
      <ConfigProvider locale={ruRU}>
        <LayoutPlatform>
          <Router basepath={withPrefix("/platform")}>
            <PlatformIndex path="home" />
            <OrdersPage path="orders" />
            <Settings path="settings">
              {/*TODO: пока скрываем*/}
              {/*<UsersList path="settings/users" />*/}
              {/*<RoleList path="settings/roles" />*/}
              {/*<LogsList path="settings/logs" />*/}
              {/*<LimitSettingsPage path="settings/limitSettings" />*/}
              {/*<GeneralSettings path="settings/generalSettings" />*/}
              {/*<NewslettersNotifications path="settings/newsNotifications" />*/}
              <AboutCompany path="/settings/about" />
              <UserProfile path="/settings/companyProfile" />
            </Settings>
            {showSuppliersList && (
              <>
                <Suppliers path="suppliers" />
                <SupplierCard path="/suppliers/:supplierId" />
              </>
            )}
            <Catalog path="catalog">
              <CatalogPage
                links={categoryLinks}
                path="catalog" />
              <CatalogVerificationPage path="catalog/verification" />
            </Catalog>
            <CatalogProductCard
              links={categoryLinks}
              path="catalog/products/:productId"
            />
            <CatalogProductCard
              links={categoryLinks}
              path="catalog/:categoryId/products/:productId"
            />
            <Cart path="cart" />
            <Checkout path="cart/:supplierId" />
            <Search path="search" />
            <CardOrder path="order/:orderId" />
            <Reports path="reports" />
          </Router>
        </LayoutPlatform>
      </ConfigProvider>
    </>
  );
};

export default Platform;

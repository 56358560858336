import React, { FC, useEffect, useState } from "react";
import dayjs from "dayjs";
import { useSelector } from "react-redux";

import { roleResolver } from "@/utils/roleResolver";
import { orderStatusResolver } from "@/utils/orderStatusResolver";

import AdaptableBlock from "@/components/ui-kit/adaptableBlock";
import Checkbox from "@/components/ui-kit/checkox";
import { Dropdown, MenuProps } from "antd";
import { MoreOutlined } from "@ant-design/icons";

import css from "./index.modules.scss";

import { IDocument } from "app/types";
import { AppStateType } from "@/reducers";
import { StatusNameOrderType } from "@/utils/statusNames";

type TablePositionItemType = {
  item: IDocument;
  isChecked?: boolean;
  handleCheckboxClick?: (productId: number) => void;
  handleDeletePosition?: (productId: number) => void;
};

const TablePositionItem: FC<TablePositionItemType> = ({
  item,
  isChecked,
  handleCheckboxClick,
  handleDeletePosition,
}) => {
  const { userInfo } = useSelector((state: AppStateType) => state.account);
  const { order } = useSelector((state: AppStateType) => state.orders);

  const {
    isSupplier,
    isController
  } = roleResolver(userInfo?.roles);

  const {
    isNew,
    isProcessing,
    isUpdated,
    isPreparation,
  } = orderStatusResolver(order?.status as StatusNameOrderType);

  const [canUseActions, setCanUseActions] = useState<boolean>(false);

  useEffect(() => {
    const rolePermissions: Record<string, boolean> = {
      supplier: isSupplier && (isNew || isProcessing || isUpdated || isPreparation),
    };

    const isShow: boolean = Object.values(rolePermissions).some(Boolean);

    setCanUseActions(isShow);
  }, [order, userInfo]);

  const deleteOrderPosition = (): void => {
    if (!!handleDeletePosition && !!item) {
      handleDeletePosition(item.id);
    }
  };

  const handleMenuClick = (event: any) => {
    if (event.key === `delete-${item.id}`) {
      deleteOrderPosition();
    }
    if (event.key === `edit-${item.id}`) {
      // TODO: На данный момент реализация без редактирования
    }
  };

  const dropdownMenu: MenuProps["items"] = [
    {
      key: `delete-${item.id}`,
      label: "Удалить",
      disabled: isController
    },
    // TODO: Временно скрыта кнопка редактирования, как будем его делать вернуть
    // {
    //   key: `edit-${item.id}`,
    //   label: "Изменить",
    //   disabled: isController
    // },
  ]

  const menuProps: MenuProps = {
    items: dropdownMenu,
    onClick: handleMenuClick,
  };

  return (
    <tr className={css.row}>
      <td className={css.data}>
        {canUseActions && item && (
          <div className="mt-3 tablet:mt-0 text-black">
            <Checkbox
              containerClassName="justify-center"
              checked={isChecked}
              onChange={() => handleCheckboxClick(item.id)}
              disabled={!item?.id}
            />
          </div>
        )}
      </td>
      <td className={`text-t1 ${css.data}`}>
        <AdaptableBlock
          containerClassName="text-s1 font-medium text-black"
          itemName={item?.fileTypeLabel ?? "Другой документ"}
          stringLength={60}
        />
      </td>
      <td className={`text-t1 ${css.data}`}>
        <a
          className="p-0 border-none"
          download={item.fileName}
          href={item.fileUrl}
          target="_blank"
        >
          <AdaptableBlock
            containerClassName="text-s1 font-medium text-black"
            itemName={item?.fileName}
            stringLength={100}
          />
        </a>

      </td>
      <td className={`text-t1 ${css.data}`}>
        {dayjs(item?.createdAt).format("DD.MM.YYYY")}
      </td>
      {canUseActions && (
        <td>
          <div className={css.menuWrapper}>
            <div className={`flex ${css.menu}`}>
              <Dropdown menu={menuProps}>
                <MoreOutlined className={`cursor-pointer ${css.menuIcon}`} rev={undefined} />
              </Dropdown>
            </div>
          </div>
        </td>
      )}
    </tr>
  );
};

export default TablePositionItem;
